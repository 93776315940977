exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-affiliations-tsx": () => import("./../../../src/pages/affiliations.tsx" /* webpackChunkName: "component---src-pages-affiliations-tsx" */),
  "component---src-pages-areas-[name]-tsx": () => import("./../../../src/pages/areas/[name].tsx" /* webpackChunkName: "component---src-pages-areas-[name]-tsx" */),
  "component---src-pages-areas-tsx": () => import("./../../../src/pages/areas.tsx" /* webpackChunkName: "component---src-pages-areas-tsx" */),
  "component---src-pages-christmas-lights-tsx": () => import("./../../../src/pages/christmas-lights.tsx" /* webpackChunkName: "component---src-pages-christmas-lights-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-form-success-tsx": () => import("./../../../src/pages/form-success.tsx" /* webpackChunkName: "component---src-pages-form-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-light-[name]-tsx": () => import("./../../../src/pages/light/[name].tsx" /* webpackChunkName: "component---src-pages-light-[name]-tsx" */),
  "component---src-pages-light-group-[name]-tsx": () => import("./../../../src/pages/light-group/[name].tsx" /* webpackChunkName: "component---src-pages-light-group-[name]-tsx" */),
  "component---src-pages-light-group-strapi-light-group-slug-tsx": () => import("./../../../src/pages/light-group/{StrapiLightGroup.slug}.tsx" /* webpackChunkName: "component---src-pages-light-group-strapi-light-group-slug-tsx" */),
  "component---src-pages-light-strapi-light-slug-tsx": () => import("./../../../src/pages/light/{StrapiLight.slug}.tsx" /* webpackChunkName: "component---src-pages-light-strapi-light-slug-tsx" */),
  "component---src-pages-lights-tsx": () => import("./../../../src/pages/lights.tsx" /* webpackChunkName: "component---src-pages-lights-tsx" */),
  "component---src-pages-link-list-tsx": () => import("./../../../src/pages/link-list.tsx" /* webpackChunkName: "component---src-pages-link-list-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process.tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-pages-project-[name]-tsx": () => import("./../../../src/pages/project/[name].tsx" /* webpackChunkName: "component---src-pages-project-[name]-tsx" */),
  "component---src-pages-project-strapi-project-slug-tsx": () => import("./../../../src/pages/project/{StrapiProject.slug}.tsx" /* webpackChunkName: "component---src-pages-project-strapi-project-slug-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-residential-showcase-tsx": () => import("./../../../src/pages/residential/showcase.tsx" /* webpackChunkName: "component---src-pages-residential-showcase-tsx" */),
  "component---src-pages-team-[name]-tsx": () => import("./../../../src/pages/team/[name].tsx" /* webpackChunkName: "component---src-pages-team-[name]-tsx" */),
  "component---src-pages-team-strapi-team-slug-tsx": () => import("./../../../src/pages/team/{StrapiTeam.slug}.tsx" /* webpackChunkName: "component---src-pages-team-strapi-team-slug-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-testimonial-[name]-tsx": () => import("./../../../src/pages/testimonial/[name].tsx" /* webpackChunkName: "component---src-pages-testimonial-[name]-tsx" */),
  "component---src-pages-testimonial-strapi-testimonial-slug-tsx": () => import("./../../../src/pages/testimonial/{StrapiTestimonial.slug}.tsx" /* webpackChunkName: "component---src-pages-testimonial-strapi-testimonial-slug-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-vendor-[name]-tsx": () => import("./../../../src/pages/vendor/[name].tsx" /* webpackChunkName: "component---src-pages-vendor-[name]-tsx" */),
  "component---src-pages-vendor-tsx": () => import("./../../../src/pages/vendor.tsx" /* webpackChunkName: "component---src-pages-vendor-tsx" */),
  "component---src-pages-venue-[name]-tsx": () => import("./../../../src/pages/venue/[name].tsx" /* webpackChunkName: "component---src-pages-venue-[name]-tsx" */),
  "component---src-pages-venue-tsx": () => import("./../../../src/pages/venue.tsx" /* webpackChunkName: "component---src-pages-venue-tsx" */),
  "component---src-pages-wedding-lookbook-tsx": () => import("./../../../src/pages/wedding/lookbook.tsx" /* webpackChunkName: "component---src-pages-wedding-lookbook-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-areas-tsx": () => import("./../../../src/templates/areas.tsx" /* webpackChunkName: "component---src-templates-areas-tsx" */),
  "component---src-templates-service-lights-tsx": () => import("./../../../src/templates/service-lights.tsx" /* webpackChunkName: "component---src-templates-service-lights-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-vendor-tsx": () => import("./../../../src/templates/vendor.tsx" /* webpackChunkName: "component---src-templates-vendor-tsx" */),
  "component---src-templates-vendorservice-tsx": () => import("./../../../src/templates/vendorservice.tsx" /* webpackChunkName: "component---src-templates-vendorservice-tsx" */),
  "component---src-templates-venue-tsx": () => import("./../../../src/templates/venue.tsx" /* webpackChunkName: "component---src-templates-venue-tsx" */)
}

